<template>
	<v-container>
		<v-data-iterator
			:items="tests"
			:items-per-page="12"
			:search="search"
			:loading="loading"
			:footer-props="{ itemsPerPageOptions }">

			<template v-slot:header>
				<v-toolbar
					class="mb-2"
					flat
					color="transparent">
					<v-toolbar-title>
						<h1>Тести</h1>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						clearable
						flat
						hide-details
						label="Пошук"></v-text-field>
				</v-toolbar>
			</template>

			<template v-slot:default="props">
				<v-row>
					<v-col
						v-for="(test, key) in props.items"
						:key="key"
						cols="12"
						sm="6"
						md="4"
						lg="3">
						<v-card class="elevation-24" height="100%">
							<v-card-title>
								<h3 class="subtitle-1 font-weight-bold">{{ test.test_name }}</h3>
							</v-card-title>
							<v-card-text>
								<v-switch
									v-model="test.is_active"
									@change="updateTest(test)"/>
								<p>{{ test.category.subject.name }}</p>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</template>

			<template #loading>
				<v-row>
					<v-col
						v-for="i in 12"
						:key="i"
						cols="12"
						sm="6"
						md="4"
						lg="3">
						<v-skeleton-loader
							type="article"></v-skeleton-loader>
					</v-col>
				</v-row>
			</template>

		</v-data-iterator>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	data: () => ({
		search: '',
		loading: true,
		itemsPerPageOptions: [12, 24, 48, -1],
	}),
	computed: {
		...mapGetters('tests', [
			'tests',
		]),
	},
	methods: {
		...mapActions('tests', [
			'loadTests',
			'updateTest',
		]),
	},
	async mounted() {
		await this.loadTests()
	},
}
</script>